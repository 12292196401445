import React from "react";
import style from "./Navbar.css";
import Navlinks from "./Navlinks.jsx";
import Apilinks from "./Apilinks.jsx";

const Navbar = () => {
  // const handleClick = () => {
  //   window.open("https://dev.koireader.com/signupLogin?action=signup");
  // };
  return (
    <div className="menu-bar" style={style}>
      {/* <div className="menu-title">
        <span className="dashboard-link" onClick={handleClick}>
          Sign up for a developer key
        </span>
      </div> */}
      <Navlinks />
      <div className="menu-header">API REFERENCE</div>
      <Apilinks />
    </div>
  );
};

export default Navbar;
