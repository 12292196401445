import { Skeleton } from "antd";
import React from "react";

const Loader = () => {
  const skeleton = (
    <div style={{ padding: "10px", width: "100%" }}>
      {[0, 1, 2, 3].map((key) => (
        <div style={{ padding: "10px" }} key={key}>
          <Skeleton active />
        </div>
      ))}
    </div>
  );
  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <div style={{ width: "25%" }}>{skeleton}</div>
      <div style={{ width: "40%" }}>{skeleton}</div>
      <div style={{ width: "35%" }}>{skeleton}</div>
    </div>
  );
};

export default Loader;
