import React, { Fragment, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header.jsx";
import Loader from "./components/Loader.jsx";
import Extra from "./components/Extra.jsx";

const Home = lazy(() => import("./components/home/Home.jsx"));
const Api = lazy(() => import("./components/Api.jsx"));

const App = () => {
  return (
    <Router>
      <Fragment>
        <div className="App">
          <Header />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/api/:type/:name/:version" component={Api} />
              <Route path="/api/:type/:name" component={Extra} />
              {/* if nothing fits the available route patterns, redirect to home */}
              <Route path="*" component={() => <Redirect to="/" />}></Route>
            </Switch>
          </Suspense>
        </div>
      </Fragment>
    </Router>
  );
};

export default App;
