import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
// eslint-disable-next-line
import PropTypes from "prop-types";

const Info = ({ children }) => {
  return (
    <div className="marked">
      <InfoCircleOutlined /> {children}
    </div>
  );
};

Info.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Info;
