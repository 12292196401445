import React from "react";
import { Menu } from "antd";
import { HashLink } from "react-router-hash-link";

const Navlinks = () => {
  return (
    <Menu mode="vertical">
      <div className="menu-header">GETTING STARTED</div>
      <Menu.Item className="menu-link">
        <HashLink to="/#intro" smooth>
          Introduction
        </HashLink>
      </Menu.Item>
      <Menu.Item className="menu-link">
        <HashLink to="/#auth" smooth>
          Authentication
        </HashLink>
      </Menu.Item>
      <Menu.Item className="menu-link">
        <HashLink to="/#rate_limits" smooth>
          Rate Limiting
        </HashLink>
      </Menu.Item>
      <Menu.Item className="menu-link">
        <HashLink to="/#req_ids" smooth>
          Request IDs
        </HashLink>
      </Menu.Item>
      <Menu.Item className="menu-link">
        <HashLink to="/#versioning" smooth>
          Versioning
        </HashLink>
      </Menu.Item>
      <div className="menu-header">COMMON PATTERNS</div>
      <Menu.Item className="menu-link">
        <HashLink to="/#http-response-codes" smooth>
          HTTP Response Codes
        </HashLink>
      </Menu.Item>
    </Menu>
  );
};

export default Navlinks;
