import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import specs from "../../specs/specs";

const Apilinks = () => {
  return (
    <div>
      {Object.entries(specs).map(([category, allSpecs]) => (
        <Menu mode="vertical" key={category}>
          <div className="menu-header-small">{category.toUpperCase()}</div>
          {Object.entries(allSpecs).map(([type, spec]) => {
            let href = null;

            if (typeof spec.type !== "string") {
              console.error(
                `spec.type is not a string for ${category}/${type}`
              );
            }

            // for anything besides spec.type === 'api'
            if (spec.type !== "api") {
              href = `/api/${category}/${type}`;
            }

            // keep this for last always
            if (!href) {
              const allVersions = Object.keys(spec.specUrls);
              const latestVersion = allVersions[0];
              href = `/api/${category}/${type}/${latestVersion}`;
            }

            return (
              <Menu.Item
                className="menu-link"
                key={type}
                style={{ paddingLeft: "25px" }}
              >
                <Link to={href}>{spec.name}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      ))}
    </div>
  );
};

export default Apilinks;
