import SupportedDocuments from "../components/apiRefComps/SupportedDocuments.jsx";

/**
 * FORMAT
 * Category: {
 *   name(to be shown as URL param): {
 *     type: (api or common),
 *     specName: (to be shown in navigation menu),
 *     specUrls: { // order: latest to oldest
 *        API-version: {
 *          url: spec-url,
 *          fileName: name with which json file will be downloaded,
 *          collection: collection url
 *        },
 *     },
 *   },
 * }
 */
/**
 * type: api is specially reserved for openapi specs
 */

/**
 * The specUrls order could be oldest first following newer ones
 * But this way, we need to reverse the array in the API page when
 * it loads. If we use back button and come back to API page, the
 * reversed array gets reversed again. Hence, we're using order
 * from latest to oldest.
 */

export default {
  documents: {
    supported_documents: {
      type: "supported_documents",
      name: "Supported Documents",
      component: SupportedDocuments,
    },
    // document_digitization: {
    //   type: "api",
    //   name: "Document Digitization",
    //   specUrls: {
    //     "2020-12-20": {
    //       url: "https://petstore3.swagger.io/api/v3/openapi.json",
    //       fileName: "test3",
    //       collection:
    //         "https://www.getpostman.com/collections/eaa8ea1e17f32461f6a4",
    //     },
    //     "2020-11-20": {
    //       url: "https://petstore3.swagger.io/api/v3/openapi.json",
    //       fileName: "test2",
    //       collection:
    //         "https://www.getpostman.com/collections/eaa8ea1e17f32461f6a4",
    //     },
    //     "2020-10-20": {
    //       url: "https://petstore3.swagger.io/api/v3/openapi.json",
    //       fileName: "test",
    //       collection:
    //         "https://www.getpostman.com/collections/7916042bc3a274f31c86",
    //     },
    //   },
    // },
  },
  webhooks: {
    webhook_management: {
      type: "api",
      name: "Webhook Management",
      specUrls: {
        "2024-03-26": {
          url: "https://d2fedaqd9kwzub.cloudfront.net/documentation/spec/webhooks/openapi.json",
          fileName: "webhook_management",
          collection:
            "https://www.getpostman.com/collections/eaa8ea1e17f32461f6a4",
        },
      },
    },
  },
};
