import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Menu, Dropdown, Tooltip } from "antd";
import {
  FilePdfTwoTone,
  DownOutlined,
  FileTextTwoTone,
} from "@ant-design/icons";
import style from "./Header.css";
import specs from "../../specs/specs";
import Postman from "./Postman.jsx";
// eslint-disable-next-line
import PropTypes from "prop-types";

const Header = ({ history }) => {
  const home = () => {
    window.location.href = "/";
  };

  let [specUrls, setSpecUrls] = useState(false);
  let [path, setPath] = useState(false);

  const setLoader = () => {
    document.getElementById("my-loader").style.visibility = "visible";
  };

  const isValidVersion = (string) => !isNaN(Date.parse(string));

  const getParams = (location) => {
    let type = location[4];
    let spec = location[5];
    let version = location[6]?.split("#")[0];
    return [type, spec, version];
  };

  useEffect(() => {
    let location = window.location.href.split("/");
    if (location.length > 4) {
      let [type, spec, version] = getParams(location);
      if (!isValidVersion(version)) return;
      setPath([type, spec, version]);
      setSpecUrls(specs[type][spec].specUrls);
    }
    history.listen((location) => {
      let pathArray = location.pathname.split("/");
      if (pathArray.length >= 4 && !specUrls) {
        let [type, spec, version] = pathArray.splice(-3);
        if (!isValidVersion(version)) return;
        setPath([type, spec, version]);
        setSpecUrls(specs[type][spec].specUrls);
      }
    });
  }, [window.location.href]);

  const menu = specUrls && path && (
    <Menu>
      {Object.keys(specUrls).map(
        (url, index) =>
          url !== path[2] && (
            <Menu.Item key={index} onClick={setLoader}>
              <Link to={`/api/${path[0]}/${path[1]}/${url}`}>{url} </Link>
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const handleClick = () => {
    const rapiPdf = document.getElementById("pdf-button");
    let location = window.location.href.split("/");
    let [type, spec, version] = getParams(location);
    let specUrls = specs[type][spec].specUrls;
    rapiPdf.generatePdf(specUrls[version].url);
  };

  return (
    <div className="App-header">
      <div className="nav-header">
        <img
          src="https://d2fedaqd9kwzub.cloudfront.net/logo/KoiReader_Logo_Color.png"
          className="App-logo"
          alt="logo"
          onClick={home}
        />
        <h1 className="App-title" onClick={home}>
          KoiReader API Documentation
        </h1>
      </div>
      <div
        className="header-icons"
        style={{
          ...style,
          // width: specUrls && Object.keys(specUrls).length > 1 ? "33em" : "19em",
        }}
      >
        {specUrls && Object.keys(specUrls).length > 1 && (
          <div className="version-dropdown">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <span className="version-tag">
                  <span className="dropdown-tag">Version: </span>
                  {path[2]}&nbsp;
                </span>
                <DownOutlined style={{ fontSize: "12px" }} />
              </a>
            </Dropdown>
          </div>
        )}
        <div className="download-icons">
          <div className="download-icon">
            <Tooltip placement="bottom" title="Download PDF">
              <FilePdfTwoTone onClick={handleClick} />
            </Tooltip>
          </div>
          <div className="download-icon">
            <Tooltip placement="bottom" title="Download JSON">
              <a id="download-specs" href="#">
                <FileTextTwoTone />
              </a>
            </Tooltip>
          </div>
          {specUrls && path && (
            <div className="download-icon">
              <Postman collection={specUrls[path[2]].collection} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Header);
