import React, { useEffect } from "react";
// eslint-disable-next-line
import PropTypes from "prop-types";

const Postman = ({ collection }) => {
  useEffect(() => {
    (function (p, o, s, t, m, a, n) {
      !p[s] &&
        (p[s] = function () {
          (p[t] || (p[t] = [])).push(arguments);
        });
      !o.getElementById(s + t) &&
        o
          .getElementsByTagName("head")[0]
          .appendChild(
            ((n = o.createElement("script")),
            (n.id = s + t),
            (n.async = 1),
            (n.src = m),
            n)
          );
    })(
      window,
      document,
      "_pm",
      "PostmanRunObject",
      "https://run.pstmn.io/button.js"
    );
  }, []);

  let collection_url = collection.split("/");
  let collection_id = collection_url[collection_url.length - 1];

  return (
    <div
      className="postman-run-button"
      data-postman-action="collection/import"
      data-postman-var-1={collection_id}
    ></div>
  );
};

Postman.propTypes = {
  collection: PropTypes.string,
};

export default Postman;
