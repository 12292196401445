import React from "react";
import specs from "../specs/specs";

const Extra = () => {
  let location = window.location.href.split("/");
  let category = location[4];
  let type = location[5];

  let spec = specs[category][type];
  const Component = spec.component;

  if (spec.type === "api") {
    console.error(
      'Wrong routing configuration. Extra component not be rendered for spec.type === "api"'
    );
    return <></>;
  }

  return (
    <>
      <Component />
    </>
  );
};

export default Extra;
