import React from "react";
import Info from "../Info.jsx";
import Navbar from "../navbar/Navbar.jsx";
import style from "../home/Home.css";

const SupportedDocuments = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxHeight: "93vh",
        ...style,
      }}
    >
      <Navbar />
      <div className="sections" id="section-container">
        <div className="section" id="supported_docs">
          <div className="heading">Supported Documents</div>
          <br />
          <div className="info">
            <h3 style={{ fontWeight: 600 }}>File Formats</h3>
            KoiReader API supports the following file types:
            <br />
            <ul>
              <li>PDF </li>
              <li>JPEG</li>
              <li>PNG</li>
              <li>TIFF</li>
              <li>HEIC</li>
            </ul>
            Note that some of these image formats are &quot;lossy&quot; (for
            example, JPEG). Reducing file sizes for such lossy formats may
            result in a degradation of image quality, and hence, KoiReader API
            accuracy.
            <br />
            <br />
            <h3 style={{ fontWeight: 600 }}>Image Sizing</h3>
            To enable accurate data extraction from the KoiReader API, images
            should generally be a minimum of 640 x 480 pixels (about 300k
            pixels).
            <br />
            <br />
            <Info>
              Generally, KoiReader API requires images to be a sufficient size
              so that important features within the request can be easily
              distinguished. Sizes smaller than the recommended size may work,
              albeit with lower accuracy.
            </Info>
            <br />
            <br />
            <h3 style={{ fontWeight: 600 }}>File Size</h3>
            Documents sent to KoiReader API should not exceed 8MB. Reducing your
            file size can significantly improve throughput; however, be careful
            not to reduce image quality in the process. Please note that our API
            imposes a 8MB request size limit.
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportedDocuments;
